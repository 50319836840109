import { DetailsCellSkeleton, Icon } from '@revolut/ui-kit'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'

import {
  useGetEngagementSurvey,
  useRefetchEngagementSurveyRounds,
  useUpdateEngagementSurvey,
} from '@src/api/engagement'
import { mapSurveyRoundStatusToColorV2 } from '@src/apps/People/Engagement/helpers'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { API } from '@src/constants/api'
import { SURVEYS_DEFAULT_ICON } from '@src/constants/common'
import { ROUTES } from '@src/constants/routes'
import { EntityAvatar } from '@src/features/EntityAvatar/EntityAvatar'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { isHubAppPath } from '../helpers/isHubApp'
import { ResultsTab } from './ResultsTab'
import { SurveyActions } from './SurveyActions'
import { SurveyOverview } from './SurveyOverview'
import { AnswersTab } from './v2/AnswersTab'
import { EngagementSurveyResultsItemDetails } from './v2/Details'
import { ResultsTab as ResultsTabV2 } from './v2/ResultsTab'
import { useTimelineFilter } from './v2/ResultsTab/TimelineFilter/useTimelineFilter'
import { useHeatmapFilters } from './v2/ResultsTab/hooks/useHeatmapFilters'

const getTabsConfig = (isEngagementV2: boolean) =>
  [
    {
      title: 'Overview',
      icon: <Icon name="Profile" size={16} />,
      path: [
        ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_OVERVIEW,
        ROUTES.APPS.ENGAGEMENT.SURVEY_OVERVIEW,
      ],
      appUrl: ROUTES.APPS.ENGAGEMENT.SURVEY_OVERVIEW,
      url: ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_OVERVIEW,
      component: SurveyOverview,
    },
    {
      title: 'Results',
      icon: <Icon name="Questionnaire" size={16} />,
      path: [
        ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.ANY,
        ROUTES.APPS.ENGAGEMENT.SURVEY_RESULTS.ANY,
      ],
      appUrl: ROUTES.APPS.ENGAGEMENT.SURVEY_RESULTS.CATEGORIES,
      url: ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.CATEGORIES,
      component: isEngagementV2 ? ResultsTabV2 : ResultsTab,
    },
    isEngagementV2
      ? {
          title: 'Answers',
          icon: <Icon name="Chat" size={16} />,
          path: [
            ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_COMMENTS,
            ROUTES.APPS.ENGAGEMENT.SURVEY_COMMENTS,
          ],
          appUrl: ROUTES.APPS.ENGAGEMENT.SURVEY_COMMENTS,
          url: ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_COMMENTS,
          component: AnswersTab,
        }
      : null,
  ].filter(Boolean)

export const SurveyDetail = () => {
  const { id, itemId } = useParams<{ id: string; itemId?: string }>()

  const featureFlags = useSelector(selectFeatureFlags)
  const isEngagementV2 = featureFlags.includes(FeatureFlags.EngagementV2)
  const isEngagementV2DetailsEnabled = featureFlags.includes(
    FeatureFlags.EngagementV2DetailsPages,
  )

  const {
    data: surveyData,
    isLoading: isLoadingSurveyData,
    refetch,
  } = useGetEngagementSurvey(id)
  const { refetchEngagementSurveyRounds } = useRefetchEngagementSurveyRounds(id)
  const isHubApp = isHubAppPath()

  const tabs = useMemo(
    () =>
      getTabsConfig(isEngagementV2).map(tab => ({
        ...tab,
        url: pathToUrl(isHubApp ? tab.appUrl : tab.url, { id }),
      })),
    [isEngagementV2],
  )

  const timelineFilter = useTimelineFilter(Number(id))
  const heatmapFilters = useHeatmapFilters(Number(id))

  if (!id || (!isLoadingSurveyData && !surveyData)) {
    return (
      <Redirect to={ROUTES[isHubApp ? 'APPS' : 'PERFORMANCE'].ENGAGEMENT.DASHBOARD} />
    )
  }

  const canEdit = !!surveyData?.field_options?.permissions?.includes(
    PermissionTypes.ChangeEngagementSurvey,
  )

  const onActionPerformed = () => {
    refetch()
    refetchEngagementSurveyRounds()
  }

  if (isEngagementV2DetailsEnabled && itemId) {
    return <EngagementSurveyResultsItemDetails />
  }
  return (
    <PageWrapper>
      <PageHeader
        title={
          surveyData && (
            <PageHeader.Title
              title={surveyData.title || 'Engagement survey'}
              avatar={
                <EntityAvatar
                  data={surveyData}
                  defaultIcon={SURVEYS_DEFAULT_ICON}
                  canEdit={canEdit}
                  api={useUpdateEngagementSurvey}
                  apiUrl={API.ENGAGEMENT_SURVEYS}
                />
              }
              labels={
                <PageHeader.LabelsBar>
                  {surveyData.round_status && (
                    <PageHeader.Label
                      useTag
                      color={mapSurveyRoundStatusToColorV2(surveyData)}
                    >
                      {surveyData.round_status.name}
                    </PageHeader.Label>
                  )}
                </PageHeader.LabelsBar>
              }
              actions={<SurveyActions survey={surveyData} refetch={onActionPerformed} />}
            />
          )
        }
        backUrl={ROUTES[isHubApp ? 'APPS' : 'PERFORMANCE'].ENGAGEMENT.DASHBOARD}
        isLoading={isLoadingSurveyData}
      />
      <PageBody maxWidth={undefined} rowGap="s-16">
        <TabBarNavigation tabs={tabs} />
        {isLoadingSurveyData ? (
          <DetailsCellSkeleton />
        ) : (
          <Switch>
            {tabs.map(tab => (
              <Route path={tab.path} key={tab.title}>
                <tab.component
                  survey={surveyData}
                  refetch={onActionPerformed}
                  timelineFilter={timelineFilter}
                  heatmapFilters={heatmapFilters}
                />
              </Route>
            ))}
          </Switch>
        )}
      </PageBody>
    </PageWrapper>
  )
}
