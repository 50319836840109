import React from 'react'
import { isNumber } from 'lodash'
import { matchPath, useParams } from 'react-router-dom'
import {
  FilterButtonSkeleton,
  Flex,
  Highlights,
  HStack,
  TabBar,
  Token,
} from '@revolut/ui-kit'

import { navigateReplace } from '@src/actions/RouterActions'
import { useGetSurveyAnalytics } from '@src/api/engagement'
import {
  overallScoreToColor,
  participationRateToColor,
} from '@src/apps/People/Engagement/helpers'
import FilterButtonCheckboxSelect from '@src/components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import Stat from '@src/components/Stat/Stat'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import Table from '@src/components/TableV2/Table'
import TableLoader from '@src/components/TableV2/TableLoader'
import { selectorKeys } from '@src/constants/api'
import { FilterByInterface } from '@src/interfaces/data'
import { EngagementSurveyInterface, GroupByOptionItem } from '@src/interfaces/engagement'
import { formatPercentage } from '@src/utils/format'
import { history, pathToUrl } from '@src/utils/router'
import { toIdAndName, toLabeledIdAndName } from '@src/utils/toIdAndName'
import { allSurveyResultsBaseRoutes, ItemsToAnalyse, ViewMode } from './common'
import { SurveySelector, UseSurveySelectorReturnType } from './SurverySelector'
import { TableContent } from './TableContent'
import { TimelineFilter } from './TimelineFilter'
import { UseTimelineFilterReturnType } from './TimelineFilter/useTimelineFilter'
import { useQuery } from '@src/utils/queryParamsHooks'
import { UseHeatmapFiltersReturnType } from './hooks/useHeatmapFilters'

const getAnalysedItemsBaseRoute = () => {
  return allSurveyResultsBaseRoutes.find(
    route => !!matchPath(history.location.pathname, route.ANY),
  )?.ANY
}

interface Props {
  survey: EngagementSurveyInterface | undefined
  timelineFilter: UseTimelineFilterReturnType
  heatmapFilters: UseHeatmapFiltersReturnType
  scopeFilters?: FilterByInterface[]
  surveySelector?: UseSurveySelectorReturnType
}
export const ResultsTab = ({
  survey,
  timelineFilter,
  heatmapFilters,
  scopeFilters,
  surveySelector,
}: Props) => {
  const params = useParams<{ type: ItemsToAnalyse; subtab: ItemsToAnalyse }>()
  const itemsToAnalyseFromPath = params.type || params.subtab

  const { query, changeQueryParam } = useQuery<{ viewMode?: ViewMode }>()
  const { viewMode = 'table' } = query

  const { data: surveyAnalytics, isLoading: isLoadingSurveryAnalytics } =
    useGetSurveyAnalytics(survey?.id)

  const isScopedView = !!scopeFilters

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Flex width="100%" justifyContent="space-between">
          <Highlights>
            <Stat
              color={overallScoreToColor(surveyAnalytics?.average_score)}
              val={
                isLoadingSurveryAnalytics
                  ? undefined
                  : surveyAnalytics?.average_score || 'N/A'
              }
              label="Average score"
            />
            <Stat
              val={
                isLoadingSurveryAnalytics
                  ? undefined
                  : surveyAnalytics?.audience_size || 'N/A'
              }
              label="Audience size"
              tooltip="This is the number of active employees who were requested to complete the survey"
            />
            <Stat
              val={
                isLoadingSurveryAnalytics
                  ? undefined
                  : formatPercentage(surveyAnalytics?.response_rate || null, 2)
              }
              label="Participation"
              tooltip="This is based on the amount of employees who finished the survey (i.e. who answered all questions)"
              color={
                !surveyAnalytics?.response_rate
                  ? Token.color.foreground
                  : participationRateToColor(
                      Math.floor(
                        isNumber(surveyAnalytics?.response_rate)
                          ? surveyAnalytics.response_rate * 100
                          : 0,
                      ),
                    )
              }
            />
          </Highlights>
          <TabBar
            variant="segmented fit"
            mx="auto"
            value={viewMode}
            onChange={value => {
              if (value) {
                changeQueryParam('viewMode', value)
              }
            }}
          >
            <TabBar.Item
              useIcon="AvatarGrid"
              to="heatmap"
              aria-label="show heatmap view"
            />
            <TabBar.Item useIcon="16/List" to="table" aria-label="show list view" />
          </TabBar>
        </Flex>
      </Table.Widget.Info>
      <Table.Widget.Filters>
        {surveySelector && <SurveySelector {...surveySelector} />}
        <TimelineFilter hideModeSwitcher={isScopedView} {...timelineFilter} />
        {viewMode === 'heatmap' && (
          <>
            {heatmapFilters.groupBy.isLoadingAvailableItems ? (
              <FilterButtonSkeleton width={150} />
            ) : (
              <FilterButtonCheckboxSelect<GroupByOptionItem>
                searchable
                label={heatmapFilters.groupBy.value.name}
                options={heatmapFilters.groupBy.availableItems}
                value={heatmapFilters.value}
                onChange={newValue => {
                  if (heatmapFilters.groupBy && newValue) {
                    heatmapFilters.setValue(newValue)
                  } else {
                    heatmapFilters.setValue([])
                  }
                }}
              />
            )}
          </>
        )}
      </Table.Widget.Filters>
      <Table.Widget.Actions>
        <HStack space="s-8">
          <RadioSelectInput
            inputProps={{ width: 180 }}
            label="Analyse"
            searchable={false}
            value={toIdAndName(itemsToAnalyseFromPath || 'questions')}
            options={['questions' as const, 'categories' as const].map(
              toLabeledIdAndName,
            )}
            onChange={value => {
              if (survey && value) {
                const path = getAnalysedItemsBaseRoute()

                if (path) {
                  navigateReplace(
                    pathToUrl(
                      path,
                      isScopedView
                        ? { ...params, subtab: value.id }
                        : { ...params, type: value.id },
                      query,
                    ),
                  )
                }
              }
            }}
          />
          {viewMode === 'heatmap' && (
            <RadioSelectInput
              inputProps={{ width: 180 }}
              label="Group by"
              searchable={false}
              value={heatmapFilters.groupBy.value}
              selector={selectorKeys.engagement_analytics_heatmap_groups}
              onChange={value => {
                if (value?.id) {
                  heatmapFilters.groupBy.setValue(value)
                }
              }}
            />
          )}
        </HStack>
      </Table.Widget.Actions>
      {!surveySelector?.isLoadingOptions && survey ? (
        <Table.Widget.Table>
          {timelineFilter.round.options.length ? (
            <TableContent
              viewMode={viewMode}
              surveyId={survey.id}
              heatmapFilters={heatmapFilters}
              timelineFilter={timelineFilter}
              scopeFilters={scopeFilters}
              itemsToAnalyse={itemsToAnalyseFromPath}
              isLoading={
                heatmapFilters.isLoadingGroupByOptions ||
                heatmapFilters.groupBy.isLoadingAvailableItems
              }
            />
          ) : (
            <EmptyTableRaw
              title="This survey does not have any rounds"
              description="Start the survey to get some results"
              imageId="3D018"
            />
          )}
        </Table.Widget.Table>
      ) : (
        <TableLoader rowHeight="large" />
      )}
    </Table.Widget>
  )
}
