import React, { useEffect, useMemo, useState } from 'react'
import { TextButton, useToggle } from '@revolut/ui-kit'

import { engagementAnswersTableRequests } from '@src/api/engagement'
import SideBar from '@src/components/SideBar/SideBar'
import Stat from '@src/components/Stat/Stat'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { useTable } from '@src/components/TableV2/hooks'
import {
  engagementResultsV2AnsweredOnColumn,
  engagementResultsV2AnswerScoreColumn,
  engagementResultsV2AnswerTextColumn,
  engagementResultsV2CategoryColumn,
  engagementResultsV2QuestionTextColumn,
  isEmptyAnswerTextColName,
} from '@src/constants/columns/engagementResultsV2'
import { TableNames } from '@src/constants/table'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import {
  EngagementAnswerInterface,
  EngagementSurveyInterface,
} from '@src/interfaces/engagement'
import { toIdAndName } from '@src/utils/toIdAndName'
import { UseSurveySelectorReturnType } from '../ResultsTab/SurverySelector'
import { TimelineFilter } from '../ResultsTab/TimelineFilter'
import { UseTimelineFilterReturnType } from '../ResultsTab/TimelineFilter/useTimelineFilter'
import { UseHeatmapFiltersReturnType } from '../ResultsTab/hooks/useHeatmapFilters'
import { AcknowledgementSidebar } from './AcknowledgementSidebar'

const getRow = (
  onClickRow: (rowData: EngagementAnswerInterface) => void,
): RowInterface<EngagementAnswerInterface> => ({
  linkToForm: data => {
    onClickRow(data)
  },
  cells: [
    {
      ...engagementResultsV2QuestionTextColumn,
      width: 400,
    },
    {
      ...engagementResultsV2AnswerTextColumn,
      width: 400,
    },
    {
      ...engagementResultsV2CategoryColumn,
      width: 200,
    },
    {
      ...engagementResultsV2AnswerScoreColumn,
      width: 200,
    },
    {
      ...engagementResultsV2AnsweredOnColumn,
      width: 200,
    },
  ],
})

interface Props {
  survey: EngagementSurveyInterface | undefined
  timelineFilter: UseTimelineFilterReturnType
  heatmapFilters: UseHeatmapFiltersReturnType
  scopeFilters?: FilterByInterface[]
  surveySelector?: UseSurveySelectorReturnType
}
export const AnswersTab = ({ survey, timelineFilter, scopeFilters }: Props) => {
  const [sidebarOpen, toggleSidebarOpen] = useToggle()
  const [selectedAnswer, setSelectedAnswer] = useState<EngagementAnswerInterface>()

  const isScopedView = !!scopeFilters

  const table = useTable<EngagementAnswerInterface>(
    engagementAnswersTableRequests,
    [
      {
        columnName: isEmptyAnswerTextColName,
        filters: [toIdAndName('false')],
      },
      {
        columnName: 'creation_date_time',
        filters: [
          toIdAndName(timelineFilter.dateFrom),
          toIdAndName(timelineFilter.dateTo),
        ],
      },
      survey?.id
        ? {
            columnName: 'survey__id',
            filters: [toIdAndName(String(survey.id))],
            nonResettable: true,
          }
        : null,
    ].filter(Boolean),
    undefined,
    { disable: !survey?.id },
  )

  useEffect(() => {
    if (table.loading) {
      return
    }
    table.onFilterChange([
      ...table.filterBy.filter(
        filterItem => filterItem.columnName !== 'creation_date_time',
      ),
      {
        columnName: 'creation_date_time',
        filters: [
          toIdAndName(timelineFilter.dateFrom),
          toIdAndName(timelineFilter.dateTo),
        ],
      },
    ])
  }, [timelineFilter.dateFrom, timelineFilter.dateTo])

  const row = useMemo(
    () =>
      getRow(data => {
        setSelectedAnswer(data)
        toggleSidebarOpen.on()
      }),
    [],
  )

  return (
    <>
      <Table.Widget>
        <Table.Widget.Info>
          <Stat label="Comments" val={table?.loading ? undefined : table?.count} />
        </Table.Widget.Info>
        <Table.Widget.Filters>
          <TimelineFilter hideModeSwitcher={isScopedView} {...timelineFilter} />
        </Table.Widget.Filters>
        <Table.Widget.Table>
          <AdjustableTable
            {...table}
            hideCount
            useWindowScroll
            dataType="Comment"
            name={TableNames.EngagementSurveysResultComments}
            emptyState={
              <EmptyTableRaw
                title="No comments found"
                action={
                  table.filterBy ? (
                    <TextButton
                      onClick={() => {
                        table.resetFiltersAndSorting()
                        timelineFilter.clearAll()
                      }}
                    >
                      Clear filters
                    </TextButton>
                  ) : null
                }
              />
            }
            row={row}
          />
        </Table.Widget.Table>
      </Table.Widget>
      {selectedAnswer && (
        <SideBar
          variant="medium"
          isOpen={sidebarOpen}
          onClose={toggleSidebarOpen.off}
          title={selectedAnswer?.question.question_text}
        >
          <AcknowledgementSidebar
            answerId={selectedAnswer.id}
            closeSidebar={toggleSidebarOpen.off}
          />
        </SideBar>
      )}
    </>
  )
}
