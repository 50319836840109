import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { navigateTo } from '@src/actions/RouterActions'
import { getEngagementResultsTableV2Requests } from '@src/api/engagement'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useIsNewTable, useTable } from '@src/components/TableV2/hooks'
import {
  engagementResultsV2AverageScoreColumn,
  engagementResultsV2CategoryNameColumn,
} from '@src/constants/columns/engagementResultsV2'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { EngagementResultV2Interface } from '@src/interfaces/engagement'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { mainEntrypointBaseRoutes, ResultsInterface } from './common'
import {
  getDateRangeFilterParams,
  useApplyNonTableFilters,
} from './hooks/useApplyNonTableFilters'

const getRow = (
  surveyId: number,
  canViewDetails: boolean,
): RowInterface<EngagementResultV2Interface> => ({
  linkToForm: canViewDetails
    ? ({ id: categoryId }) =>
        navigateTo(
          pathToUrl(ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.CATEGORIES, {
            id: surveyId,
            categoryId,
          }),
        )
    : undefined,
  cells: [
    { ...engagementResultsV2CategoryNameColumn, width: 250 },
    { ...engagementResultsV2AverageScoreColumn, width: 105 },
  ],
})

export const ResultCategoriesTable = ({
  viewMode,
  surveyId,
  timelineFilter,
  scopeFilters,
  isLoading,
}: ResultsInterface) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isEngagementV2DetailsEnabled = featureFlags.includes(
    FeatureFlags.EngagementV2DetailsPages,
  )
  const isNewTable = useIsNewTable()
  const table = useTable<EngagementResultV2Interface>(
    getEngagementResultsTableV2Requests(surveyId, 'drivers'),
    getDateRangeFilterParams(timelineFilter.dateFrom, timelineFilter.dateTo),
    undefined,
    { disable: viewMode !== 'table', disableQuery: true, disableOnEmptyFilters: true },
  )
  useApplyNonTableFilters({
    disable: Boolean(isLoading || table.loading || viewMode !== 'table'),
    table,
    timelineFilter,
    scopeFilters,
    itemsToAnalyse: 'categories',
  })

  const isMainEntryPoint = useRouteMatch(mainEntrypointBaseRoutes.map(route => route.ANY))
  const row = useMemo(
    () => getRow(surveyId, !!isMainEntryPoint && isEngagementV2DetailsEnabled),
    [surveyId, isMainEntryPoint],
  )

  return (
    <AdjustableTable
      name={TableNames.EngagementSurveysResultCategories}
      row={row}
      hideCount={isNewTable}
      emptyState={<EmptyTableRaw title="Results were not found" />}
      useWindowScroll
      {...table}
    />
  )
}
