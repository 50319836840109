import { ROUTES } from '@src/constants/routes'
import { FilterByInterface } from '@src/interfaces/data'
import { EngagementResultsScope } from '@src/interfaces/engagement'
import { UseTimelineFilterReturnType } from './TimelineFilter/useTimelineFilter'
import { UseHeatmapFiltersReturnType } from './hooks/useHeatmapFilters'

export const MAX_ITEMS_ALLOWED_FOR_ANALYSIS = 10

export const mainEntrypointBaseRoutes = [
  ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS,
  ROUTES.APPS.ENGAGEMENT.SURVEY_RESULTS,
]

export const entityLevelEntrypointBaseRoutes = [
  ROUTES.ORGANISATION.COMPANY.ENGAGEMENT,
  ROUTES.FORMS.DEPARTMENT.ENGAGEMENT,
  ROUTES.FORMS.TEAM.ENGAGEMENT,
  ROUTES.FORMS.ROLE.ENGAGEMENT,
  ROUTES.FORMS.FUNCTION.ENGAGEMENT,
  ROUTES.FORMS.SPECIALISATIONS.ENGAGEMENT,
  ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.ENGAGEMENT,
]

export const allSurveyResultsBaseRoutes = [
  ...mainEntrypointBaseRoutes,
  ...entityLevelEntrypointBaseRoutes,
]

export type ViewMode = 'table' | 'heatmap'
export type TimelineFilterMode = 'rounds' | 'calendar'
export type ItemsToAnalyse = 'questions' | 'categories'

export type ResultsInterface = {
  viewMode: ViewMode
  surveyId: number
  timelineFilter: UseTimelineFilterReturnType
  heatmapFilters: UseHeatmapFiltersReturnType
  scopeFilters?: FilterByInterface[]
  itemsToAnalyse: ItemsToAnalyse
  isLoading: boolean
  entity?: { type: EngagementResultsScope; id: number }
}

export type PublishedResultsScope =
  | 'company'
  | 'department'
  | 'function'
  | 'role'
  | 'specialisation'
  | 'team'
  | 'employee'
