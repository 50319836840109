import { useEffect, useMemo, useState } from 'react'

import { useGetGroupByOptionItems } from '@src/api/engagement'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { GroupByOption, GroupByOptionItem } from '@src/interfaces/engagement'
import { OptionInterface } from '@src/interfaces/selectors'
import { toIdAndName } from '@src/utils/toIdAndName'
import { MAX_ITEMS_ALLOWED_FOR_ANALYSIS } from '../common'

export type UseHeatmapFiltersReturnType = {
  value: GroupByOptionItem[] | undefined
  setValue: (newValue: OptionInterface[]) => void
  groupByOptions: GroupByOption[] | undefined
  isLoadingGroupByOptions: boolean
  groupBy: {
    value: GroupByOption
    setValue: (newValue: GroupByOption) => void
    availableItems: GroupByOption[]
    isLoadingAvailableItems: boolean
    paramKey: string | undefined
    paramValue: string | undefined
  }
  clearAll: VoidFunction
}

export const useHeatmapFilters = (
  surveyId: number | undefined,
): UseHeatmapFiltersReturnType => {
  const [groupBy, setGroupBy] = useState<GroupByOption>(toIdAndName('role', true))

  const [defaultGroupByOptionItems, setDefaultGroupByOptionItems] = useState<
    Partial<Record<GroupByOption['id'], GroupByOptionItem[]>>
  >({})
  const [selectedGroupByOptionItems, setSelectedGroupByOptionItems] = useState<
    Partial<Record<GroupByOption['id'], GroupByOptionItem[]>>
  >({})

  const { data: groupByOptions, isLoading: isLoadingGroupByOptions } =
    useGetSelectors<GroupByOption>(selectorKeys.engagement_analytics_heatmap_groups)
  const { data: groupByOptionItemsData, isLoading: isLoadingGroupByOptionItems } =
    useGetGroupByOptionItems(surveyId, groupBy?.id)
  const groupByOptionAvailableItems = groupByOptionItemsData?.options || []

  useEffect(() => {
    if (groupBy && groupByOptionAvailableItems.length) {
      setDefaultGroupByOptionItems({
        ...defaultGroupByOptionItems,
        [groupBy.id]: groupByOptionAvailableItems.slice(
          0,
          MAX_ITEMS_ALLOWED_FOR_ANALYSIS,
        ),
      })
    }
  }, [groupBy, groupByOptionAvailableItems])

  const valueWithFallback =
    selectedGroupByOptionItems[groupBy.id] || defaultGroupByOptionItems[groupBy.id]

  const groupByQueryParamValue = useMemo(
    () => valueWithFallback?.map(item => item.id).join(','),
    [valueWithFallback],
  )

  return {
    value: valueWithFallback,
    setValue: newValue =>
      setSelectedGroupByOptionItems({
        ...selectedGroupByOptionItems,
        [groupBy.id]: newValue,
      }),
    groupByOptions,
    isLoadingGroupByOptions,
    groupBy: {
      value: groupBy,
      setValue: setGroupBy,
      availableItems: groupByOptionAvailableItems || [],
      isLoadingAvailableItems: isLoadingGroupByOptionItems,
      paramKey: groupBy.id,
      paramValue: groupByQueryParamValue,
    },
    clearAll: () => {
      setSelectedGroupByOptionItems({})
    },
  }
}
